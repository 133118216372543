import React from "react";
import RedDot from "./reddot";

const Card = (props) => {
    
    return (
            
                <div className="cardBillionaireBuddha">
                    <div data-uk-lightbox>
                        <a href={props.imageLarge}><div className="cardBillionaireBuddha__image">
                            <img  src={props.image}/>       
                        </div></a>
                    </div>
                    
                    <div className="cardBillionaireBuddha__text">
                        <RedDot visible={props.reddot}/>
                        <div className="cardBillionaireBuddha__text__title uk-text-large uk-text-bold"> 
                            {props.text1a}
                        </div>
                        <div className="cardBillionaireBuddha__text__description">
                            {props.text1b}
                        </div>
                    </div>  
                </div>
    )                                                                                                   
}

export default Card;

