import React from 'react';
import ReactDOM from 'react-dom';
import { render } from "react-snapshot";

import './css/style.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom';

//import registerServiceWorker from './registerServiceWorker';
import { unregister as unregisterServiceWorker } from './serviceWorker';

render(
  <BrowserRouter>
    <App  />
  </BrowserRouter>
  , document.getElementById('root'));

//registerServiceWorker();
unregisterServiceWorker();
