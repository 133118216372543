import React, { Component } from 'react';

import Slideshow1_600 from "./images/slideshow/600/1.jpg";
import Slideshow1_900 from "./images/slideshow/900/1.jpg";
import Slideshow1_1200 from "./images/slideshow/1200/1.jpg";
import Slideshow1_1800 from "./images/slideshow/1800/1.jpg";
import Slideshow1_1920 from "./images/slideshow/1920/1.jpg";
import Slideshow1_2560 from "./images/slideshow/2560/1.jpg";

import Slideshow2_600 from "./images/slideshow/600/2.jpg";
import Slideshow2_900 from "./images/slideshow/900/2.jpg";
import Slideshow2_1200 from "./images/slideshow/1200/2.jpg";
import Slideshow2_1800 from "./images/slideshow/1800/2.jpg";
import Slideshow2_1920 from "./images/slideshow/1920/2.jpg";
import Slideshow2_2560 from "./images/slideshow/2560/2.jpg";

import Slideshow3_600 from "./images/slideshow/600/3.jpg";
import Slideshow3_900 from "./images/slideshow/900/3.jpg";
import Slideshow3_1200 from "./images/slideshow/1200/3.jpg";
import Slideshow3_1800 from "./images/slideshow/1800/3.jpg";
import Slideshow3_1920 from "./images/slideshow/1920/3.jpg";
import Slideshow3_2560 from "./images/slideshow/2560/3.jpg";

import Slideshow4_600 from "./images/slideshow/600/4.jpg";
import Slideshow4_900 from "./images/slideshow/900/4.jpg";
import Slideshow4_1200 from "./images/slideshow/1200/4.jpg";
import Slideshow4_1800 from "./images/slideshow/1800/4.jpg";
import Slideshow4_1920 from "./images/slideshow/1920/4.jpg";
import Slideshow4_2560 from "./images/slideshow/2560/4.jpg";

import Slideshow5_600 from "./images/slideshow/600/5.jpg";
import Slideshow5_900 from "./images/slideshow/900/5.jpg";
import Slideshow5_1200 from "./images/slideshow/1200/5.jpg";
import Slideshow5_1800 from "./images/slideshow/1800/5.jpg";
import Slideshow5_1920 from "./images/slideshow/1920/5.jpg";
import Slideshow5_2560 from "./images/slideshow/2560/5.jpg";

import Slideshow6_600 from "./images/slideshow/600/6.jpg";
import Slideshow6_900 from "./images/slideshow/900/6.jpg";
import Slideshow6_1200 from "./images/slideshow/1200/6.jpg";
import Slideshow6_1800 from "./images/slideshow/1800/6.jpg";
import Slideshow6_1920 from "./images/slideshow/1920/6.jpg";
import Slideshow6_2560 from "./images/slideshow/2560/6.jpg";

import Slideshow7_600 from "./images/slideshow/600/7.jpg";
import Slideshow7_900 from "./images/slideshow/900/7.jpg";
import Slideshow7_1200 from "./images/slideshow/1200/7.jpg";
import Slideshow7_1800 from "./images/slideshow/1800/7.jpg";
import Slideshow7_1920 from "./images/slideshow/1920/7.jpg";
import Slideshow7_2560 from "./images/slideshow/2560/7.jpg";

import Slideshow8_600 from "./images/slideshow/600/8.jpg";
import Slideshow8_900 from "./images/slideshow/900/8.jpg";
import Slideshow8_1200 from "./images/slideshow/1200/8.jpg";
import Slideshow8_1800 from "./images/slideshow/1800/8.jpg";
import Slideshow8_1920 from "./images/slideshow/1920/8.jpg";
import Slideshow8_2560 from "./images/slideshow/2560/8.jpg";

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

class Slideshow extends Component {
    render() {
      return (

        <div className="slideshow slideshow uk-home-slideshow uk-slideshow-fullscreen uk-position-relative uk-visible-toggle uk-light" data-uk-slideshow="autoplay: true; animation: fade; autoplay-interval: 5000; pause-on-hover: false " >
              <ul className="uk-slideshow-items" data-uk-height-viewport="offset-top: true;">
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                    <img data-uk-cover data-src={Slideshow1_1920} data-srcset={`${Slideshow1_600} 600w, ${Slideshow1_900} 900w, ${Slideshow1_1200} 1200w, ${Slideshow1_1800} 1800w, ${Slideshow1_1920} 1920w, ${Slideshow1_2560} 2560w`} alt="" data-uk-img/>                      
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img data-uk-cover data-src={Slideshow2_1920} data-srcset={`${Slideshow2_600} 600w, ${Slideshow2_900} 900w, ${Slideshow2_1200} 1200w, ${Slideshow2_1800} 1800w, ${Slideshow2_1920} 1920w, ${Slideshow2_2560} 2560w`} alt="" data-uk-img/>                      
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                      <img data-uk-cover data-src={Slideshow3_1920} data-srcset={`${Slideshow3_600} 600w, ${Slideshow3_900} 900w, ${Slideshow3_1200} 1200w, ${Slideshow3_1800} 1800w, ${Slideshow3_1920} 1920w, ${Slideshow3_2560} 2560w`} alt="" data-uk-img/>                      
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                    <img data-uk-cover data-src={Slideshow4_1920} data-srcset={`${Slideshow4_600} 600w, ${Slideshow4_900} 900w, ${Slideshow4_1200} 1200w, ${Slideshow4_1800} 1800w, ${Slideshow4_1920} 1920w, ${Slideshow4_2560} 2560w`} alt="" data-uk-img/>     
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                    <img data-uk-cover data-src={Slideshow5_1920} data-srcset={`${Slideshow5_600} 600w, ${Slideshow5_900} 900w, ${Slideshow5_1200} 1200w, ${Slideshow5_1800} 1800w, ${Slideshow5_1920} 1920w, ${Slideshow5_2560} 2560w`} alt="" data-uk-img/>       
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                     <img data-uk-cover data-src={Slideshow6_1920} data-srcset={`${Slideshow6_600} 600w, ${Slideshow6_900} 900w, ${Slideshow6_1200} 1200w, ${Slideshow6_1800} 1800w, ${Slideshow6_1920} 1920w, ${Slideshow6_2560} 2560w`} alt="" data-uk-img/>                
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                    <img data-uk-cover data-src={Slideshow7_1920} data-srcset={`${Slideshow7_600} 600w, ${Slideshow7_900} 900w, ${Slideshow7_1200} 1200w, ${Slideshow7_1800} 1800w, ${Slideshow7_1920} 1920w, ${Slideshow7_2560} 2560w`} alt="" data-uk-img/>                 
                  </div>
                  </li>
                  <li>
                  <div className="uk-position-cover uk-transform-origin-center-left">
                    <img data-uk-cover data-src={Slideshow8_1920} data-srcset={`${Slideshow8_600} 600w, ${Slideshow8_900} 900w, ${Slideshow8_1200} 1200w, ${Slideshow8_1800} 1800w, ${Slideshow8_1920} 1920w, ${Slideshow8_2560} 2560w`} alt="" data-uk-img/>        
                  </div>
                  </li>           
              </ul>

            <a href="#" data-uk-slidenav-previous="" className="uk-slidenav-previous uk-icon uk-slidenav uk-slidenav-large uk-position-center-left uk-position-small uk-hidden-hover" data-uk-slideshow-item="previous"></a>
            <a href="#" data-uk-slidenav-next="" className="uk-slidenav-next uk-icon uk-slidenav uk-slidenav-large uk-position-center-right uk-position-small uk-hidden-hover" data-uk-slideshow-item="next"></a>

          </div>

      );
}
};

export default Slideshow;
