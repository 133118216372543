import React from "react";
import { Link } from 'react-router-dom';
import Footer from "./footer";
import Card from "./cardCatalogs";

import Image1 from "./images/catalogs/twentyportraits/1.jpg";
import Image1b from "./images/catalogs/twentyportraits/1b.jpg";
import Image2 from "./images/catalogs/twentyportraits/2.jpg";
import Image3 from "./images/catalogs/twentyportraits/3.jpg";
import Image4 from "./images/catalogs/twentyportraits/4.jpg";
import Image5 from "./images/catalogs/twentyportraits/5.jpg";
import Image6 from "./images/catalogs/twentyportraits/6.jpg";
import Image7 from "./images/catalogs/twentyportraits/7.jpg";
import Image8 from "./images/catalogs/twentyportraits/8.jpg";
import Image9 from "./images/catalogs/twentyportraits/9.jpg";
import Image10 from "./images/catalogs/twentyportraits/10.jpg";
import Image11 from "./images/catalogs/twentyportraits/11.jpg";
import Image12 from "./images/catalogs/twentyportraits/12.jpg";
import Image13 from "./images/catalogs/twentyportraits/13.jpg";
import Image14 from "./images/catalogs/twentyportraits/14.jpg";
import Image15 from "./images/catalogs/twentyportraits/15.jpg";
import Image16 from "./images/catalogs/twentyportraits/16.jpg";

const Catalogs = () => {
    
        return (
            <div className="insidePageContainer">
                <div className="insidePageContainer__breadCrumbs">
                    <a><Link to ='/' className="insidePageContainer__link">HOME</Link></a>  /  <a><Link to ='/twentyportraits' className="insidePageContainer__link">TWENTY PORTRAITS </Link></a> 
                </div>
               
                <div className="insidePageContainer__content">
                    <Card image={Image1} imageLarge={Image1} />
                    <Card image={Image1b} imageLarge={Image1b} />
                    <Card image={Image2} imageLarge={Image2} />
                    <Card image={Image3} imageLarge={Image3} />
                    <Card image={Image4} imageLarge={Image4} />
                    <Card image={Image5} imageLarge={Image5} />
                    <Card image={Image6} imageLarge={Image6} />
                    <Card image={Image7} imageLarge={Image7} />
                    <Card image={Image8} imageLarge={Image8} />
                    <Card image={Image9} imageLarge={Image9} />
                    <Card image={Image10} imageLarge={Image10} />
                    <Card image={Image11} imageLarge={Image11} />
                    <Card image={Image12} imageLarge={Image12} />
                    <Card image={Image13} imageLarge={Image13} />
                    <Card image={Image14} imageLarge={Image14} />
                    <Card image={Image15} imageLarge={Image15} />
                    <Card image={Image16} imageLarge={Image16} />
                </div>
                <Footer />
            </div>
        )
    }

export default Catalogs;