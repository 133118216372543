import React, { Component } from 'react';
import { Route } from 'react-router-dom';



import Helmet from "react-helmet";


import Header from "./components/header";
import Slideshow from './components/slideshow.js';
import Billionairebuddha from './components/billionaireBuddha.js';
import ClassicalTraining from './components/classicalTraining.js';
import TwentyPortraits from './components/twentyPortraits.js';
import SikhGurus from './components/sikhgurus.js';
import ShivaDevi from './components/shivadevi.js';
import JourneyFromSamsaraToNirvana from './components/journeyfromsamsaratonirvana.js';
import Shows from './components/shows.js';
import Contact from './components/contact.js';
import Arjuna from './components/arjuna.js'

import TwitterImage from "./components/images/600/1.jpg";
import FacebookImage from "./components/images/600/2.jpg";

/* Variable Initialization*/
const title = "Arjuna";
const description = "Arjuna is a painter, poet, dhrupad singer, writer and kungfu fighter. He is a polymathic artist who practises art across art forms.";
const keywords = "Arjuna, Artist, Indian Artist, Delhi Artist, Polymath, Painter, Dhrupad Singer, Poet, Writer, Kungfu Fighter, Shaolin Kungfu, Dhrupad, Indian Classial Music, Brahmaforces";
const url = "https://artarjuna.com";
const sitename = "Arjuna";
const fbappid = "";
const canonicalurl = "https://artarjuna.com";
const twittersite = "";
const twittercreator = "";

  class App extends Component {
    render() {
      return (
        <div>
           <Helmet>
          <title>
                { title }
          </title>
          <meta http-equiv="Content-Type" content="text/html" />
          <meta charset="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="theme-color" content="#000000" />
          <meta
            name="description"
            content= { description } 
          />
          <meta
            name="keywords"
            content={ keywords }
          />

          <link rel="canonical" href={canonicalurl} />

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content= { title }
          />
          <meta
            property="og:description"
            content={ description }
          />
          <meta property="og:image" content={FacebookImage} />
          <meta property="og:url" content= {url} />
          <meta property="og:site_name" content={sitename} />
          <meta property="fb:app_id" content={fbappid} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={title}
          />
          <meta
            name="twitter:description"
            content={description}
          />
          <meta name="twitter:url" content={url} />
          <meta name="twitter:image" content={TwitterImage} />
          <meta name="twitter:site" content={twittersite} />
          <meta name="twitter:creator" content={twittercreator} />
        </Helmet>


            <div className="pageContainer">
                <div className="pageContainer__header"><Header /></div>   
                  <Route path="/" exact component={Billionairebuddha} />
                  <Route path="/billionairebuddha" exact component={Billionairebuddha} />
                  <Route path="/classicaltraining" component={ClassicalTraining} />
                  <Route path="/twentyportraits" component={TwentyPortraits} />
                  <Route path="/sikhgurus" component={SikhGurus} />
                  <Route path="/shivadevi" component={ShivaDevi} />
                  <Route path="/journeyfromsamsaratonirvana" component={JourneyFromSamsaraToNirvana} />
                  <Route path="/shows" component={Shows} />
                  <Route path="/arjuna" component={Arjuna} />
                  <Route path="/contact" component={Contact} />
            </div>

      </div>


      );
    }
  };
export default App;
