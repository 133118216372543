import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

const Contact = ()=> {

      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                        <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/contact' className="insidePageContainer__link">Contact</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                      <div className="contact">
                      <div className="contact__content">
                        <p><b>Email: </b> brahmaforces@gmail.com</p>
                        <p><b>Mobile: </b>9810067743</p>
                        <p><b>Landline: </b>(011) 43658526</p>
                        <p><b>Studio: </b>71, Sundar Nagar, New Delhi, 110003</p>
                      </div>
                      </div>
                  
                        <Footer />
                  </div>
            </div>
      );
}


export default Contact;
