import React from "react";

const Card = (props) => {
    const linkTo=`/projects/project_type?projectTypeId=${props.projectTypeId}`;

    return (
            
                <div className="cardShows">
                    <div data-uk-lightbox>
                        <a href={props.imageLarge}><div className="cardShows__image">
                            <img  src={props.image}/>       
                        </div></a>
                    </div>
                </div>
    )                                                                                                   
}
export default Card;

