import React from 'react';

const RedDot = (props) => {
    if (props.visible) {
    return (
        <span className="cardBillionaireBuddha__reddot"></span>
    );
    }
    else return null;
}

export default RedDot;