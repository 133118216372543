import React from "react";
import { Link } from 'react-router-dom';
import Footer from "./footer";
import Card from "./cardShows";

import Image1 from "./images/shows/1.jpg";
import Image2 from "./images/shows/2.jpg";
import Image3 from "./images/shows/3.jpg";
import Image4 from "./images/shows/4.jpg";
import Image5 from "./images/shows/5.jpg";
import Image6 from "./images/shows/6.jpg";


const text1 = () => <span>BILLIONAIRE BUDDHA</span>; 
const text2 = () => <span>20 PORTRAITS</span>;
const text3 = () => <span>ARJUNA DOES THE CLASSICAL TRAINING</span>;
const text4 = () => <span>SIKH GURUS</span>;
const text5 = () => <span>SHIVA AND THE DEVI</span>;
const text6 = () => <span>JOURNEY FROM SAMSARA TO NIRVANA</span>;

const Projects = () => {
    
        return (
            <div className="insidePageContainer">
                <div className="insidePageContainer__breadCrumbs">
                    <a><Link to ='/' className="insidePageContainer__link">HOME</Link></a>  /  <a><Link to ='/shows' className="insidePageContainer__link">SHOWS </Link></a> 
                </div>
               
                <div className="insidePageContainer__content">
                
                  <Card image={Image1} text={text1()} showId={1} />
                  <Card image={Image2} text={text2()} showId={2} />
                  <Card image={Image3} text={text3()} showId={3} />
                  <Card image={Image4} text={text4()} showId={4} />
                  <Card image={Image5} text={text5()} showId={5} />
                  <Card image={Image6} text={text6()} showId={6} />
              
                </div>
            
                <Footer />

            </div>
        )
    }

export default Projects;