import React from "react";
import { Link } from 'react-router-dom';

const Card = (props) => {
    let linkTo='';

    if (props.showId == 1) {
        linkTo=`/billionairebuddha`;
    } else if (props.showId == 2) {
        linkTo=`/twentyportraits`;
    } else if (props.showId == 3) {
        linkTo=`/classicaltraining`;
    } else if (props.showId == 4) {
        linkTo=`/sikhgurus`;
    } else if (props.showId == 5) {
        linkTo=`/shivadevi`;
    } else if (props.showId == 6) {
        linkTo=`/journeyfromsamsaratonirvana`;
    }

    return (
            <Link to = {linkTo}>
                <div className="cardGeneral cardShowMenu">
                    <a> 
                        <img src={props.image} />
                    </a>
                    <div className="cardGeneral__text">
                        {props.text}
                    </div>
                </div>
            </Link>
    )
}

export default Card;