import logo from "./images/arjunaLogo.png";


import Imghamburger from "./images/hamburger2.png";
import Imgcross from "./images/cross.png";
import MobileMenu from "./mobileMenu";

import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class Header extends Component {
  constructor(props)
    {
      super(props);
      this.state={
        showMobileMenu: false
      }
    }

    toggleShowMobileMenu = () => {
      this.setState({showMobileMenu: !this.state.showMobileMenu});
    } 

  render() {
      return (

        <div className="header-container">
            <div className="header-container__hamburgerIcon">
                <img src={Imghamburger} onClick={this.toggleShowMobileMenu}/>
            </div>

            <ul className="header-container__menuItems"> 
              <li><Link to ='/' className="link">Home</Link></li>
              <li><Link to ='/classicaltraining' className="link">Classical Training</Link></li>
              <li><Link to ='/shows' className="link">Shows</Link></li>
              <li><Link to ='/arjuna' className="link">Arjuna</Link></li>
              <li><Link to ='/contact' className="link">Contact</Link></li>
            </ul>
            <div className="header-container__logo">
                <Link to ='/' className="link"><img  className="header-container__logo--image" src={logo} /></Link>
            </div>

            { this.state.showMobileMenu &&  <div className="header-container__mobileMenu"><MobileMenu /></div> }

      </div>

      );

    }
  };

export default Header;
