import React from "react";
import { Link } from 'react-router-dom';
import Footer from "./footer";
import Card from "./cardBillionairebuddha";

import Image1 from "./images/600/1.jpg";
import Image2 from "./images/600/2.jpg";
import Image3 from "./images/600/3.jpg";
import Image4 from "./images/600/4.jpg";
import Image5 from "./images/600/5.jpg";
import Image6 from "./images/600/6.jpg";
import Image7 from "./images/600/7.jpg";
import Image8 from "./images/600/8.jpg";
import Image9 from "./images/600/9.jpg";
import Image10 from "./images/600/10.jpg";
import Image11 from "./images/600/11.jpg";
import Image12 from "./images/600/12.jpg";
import Image13 from "./images/600/13.jpg";
import Image14 from "./images/600/14.jpg";
import Image15 from "./images/600/15.jpg";
import Image16 from "./images/600/16.jpg";
import Image17 from "./images/600/17.jpg";
import Image18 from "./images/600/18.jpg";
import Image19 from "./images/600/19.jpg";
import Image20 from "./images/600/20.jpg";
import Image21 from "./images/600/21.jpg";
import Image22 from "./images/600/22.jpg";
import Image23 from "./images/600/23.jpg";
import Image24 from "./images/600/24.jpg";
import Image25 from "./images/600/25.jpg";
import Image26 from "./images/600/26.jpg";
import Image27 from "./images/600/27.jpg";
import Image28 from "./images/600/28.jpg";
import Image29 from "./images/600/29.jpg";



import ImageLarge1 from './images/1200/1.jpg';
import ImageLarge2 from "./images/1200/2.jpg";
import ImageLarge3 from  "./images/1200/3.jpg";
import ImageLarge4 from  "./images/1200/4.jpg";
import ImageLarge5 from "./images/1200/5.jpg";
import ImageLarge6 from  "./images/1200/6.jpg";
import ImageLarge7 from  "./images/1200/7.jpg";
import ImageLarge8 from  "./images/1200/8.jpg";
import ImageLarge9 from  "./images/1200/9.jpg";
import ImageLarge10 from  "./images/1200/10.jpg";
import ImageLarge11 from  "./images/1200/11.jpg";
import ImageLarge12 from  "./images/1200/12.jpg";
import ImageLarge13 from  "./images/1200/13.jpg";
import ImageLarge14 from  "./images/1200/14.jpg";
import ImageLarge15 from  "./images/1200/15.jpg";
import ImageLarge16 from  "./images/1200/16.jpg";
import ImageLarge17 from  "./images/1200/17.jpg";
import ImageLarge18 from  "./images/1200/18.jpg";
import ImageLarge19 from  "./images/1200/19.jpg";
import ImageLarge20 from  "./images/1200/20.jpg";
import ImageLarge21 from  "./images/1200/21.jpg";
import ImageLarge22 from  "./images/1200/22.jpg";
import ImageLarge23 from  "./images/1200/23.jpg";
import ImageLarge24 from  "./images/1200/24.jpg";
import ImageLarge25 from  "./images/1200/25.jpg";
import ImageLarge26 from  "./images/1200/26.jpg";
import ImageLarge27 from  "./images/1200/27.jpg";
import ImageLarge28 from  "./images/1200/28.jpg";
import ImageLarge29 from  "./images/1200/29.jpg";

const text1a = ' Inner-Stellar Space Travel'
const text1b = 'Oil on canvas, 61 x 91 cm, 2 x 3 ft, 2022'
const text2a = 'Buddha Nature'
const text2b = 'Oil on canvas, 61 x 91 cm, 2 x 3 ft, 2022'
const text3a = 'Buddha in the Jungle Hood Saving All Beings'
const text3b = 'Oil on Linen, 61 x 91 cm, 2 x 3 ft' 
const text4a = 'Man to Superman'
const text4b = 'Oil on Canvas, 61 x 122 cm, 2 x 4 ft, 2021' 
const text5a = 'Vipassana – Penetrative Insight'
const text5b = 'Oil on Linen, 91 x 122 cm, 3 x 4 ft, 2022'
const text6a = 'The Monsoon Clouds of Prana: Central Channel Entry: Light Body Construction'
const text6b = 'Oil on Canvas, 122 x 153 cm, 4 x 5 ft, 2020'
const text7a = 'Buddha Mind'
const text7b = 'Oil on Canvas, 152 x 152 cm, 5 x 5 ft, 2022'
const text8a = 'Form is Form, Emptiness is Emptiness'
const text8b = 'Oil on Canvas, 46 x 61 cm, 18 x 24 Inches, 2021'
const text9a = 'Bodhicitta'
const text9b = 'Oil on Linen, 122 x 153 cm, 4 x 5 ft, 2022'
const text10a = 'Lion Heart of Courage, Nirvana, Infinite Fearlessness'
const text10b = 'Oil on Canvas, 91 x 122 cm, 3 x 4 ft, 2020'
const text11a = 'Anuttara Samyak Sambodhi, The Peak of Utter Unexcelled Enlightenment'
const text11b = 'Digital Print using Epson Archival Inks on Heavy Archival Hahnemuhle paper, 80 x 108 cm, 31 x 42 Inches, 2023 - Limited Edition of 10'
const text12a = 'Ocean Seal Samadhi with James Webb Telescope Image'
const text12b = 'Digital Print using Epson Archival Inks on Heavy Archival Hahnemuhle paper, 60 x 60 cm, 2 x 2 ft, 2022 - Limited Edition of 10'
const text13a = 'The 4 Higher Chakras'
const text13b = 'Digital Print using Epson Archival Inks on Heavy Archival Hahnemuhle paper, 42 x 175cm, 17 x 72 Inches, 2022-Limited Edition of 10'
const text14a = 'Buddha, Dry pastel on paper'
const text14b = '20 x 30 cm, 2022'
const text15a = 'The emptiness gaze, 20 x 30 cm'
const text15b = 'Dry pastel on paper, 2019'
const text16a = 'Buddha, Dry pastel on paper'
const text16b = '20 x 30 cm, 2022'
const text17a = 'Cosmic Consciousness, Oil and Acrylic on Canvas'
const text17b = '76 x 84 cm, 30 x 33 Inches, 2022'
const text18a = 'Ocean Seal Samadhi 2'
const text18b = 'Oil and Acrylic on Canvas, 84 x 92 cm, 33 x 36 Inches, 2022'
const text19a = 'Dhyana, Oil and Acrylic on Canvas'
const text19b = '84 x 91 cm, 33 x 36 Inches, 2022'
const text20a = 'Buddha Rides Into the Hell Realms on the Black Swan, Oil and Acrylic on Canvas'
const text20b = '61 x 132 cm, 24 x 52 Inches, 2022'
const text21a = 'The Birth of the Sambhogakaya, the Enjoyment Body'
const text21b = 'Oil on Canvas, 61 x 122 cm, 2 x 4 ft, 2020'
const text22a = 'Jungle Tantra'
const text22b = 'Oil on Linen, 91 x 122 cm, 3x 4 ft, 2022'
const text23a = 'Courage Lion Heart Buddha, Oil on Canvas'
const text23b = '61 x 76 cm, 24 x 30 Inches, 2020'
const text24a = 'Mahavir Zen'
const text24b = 'Oil on Canvas, 30 x 40 cm, 2021'
const text25a = 'Samurai Buddha'
const text25b = 'Oil on Canvas, 20 x 40 cm, 2018'
const text26a = 'The Blossoming of consciousness'
const text26b = 'Oil on Canvas, 30 x 40 cm, 2022'
const text27a = 'The Blossoming of consciousness'
const text27b = 'Oil on Canvas, 30 x 40 cm, 2022'
const text28a = 'Moksha Dhian'
const text28b = 'Oil on Canvas, 20 x 40 cm, 2018'
const text29a = 'Buddha Riding Wind Horse'
const text29b = 'Acrylic on Canvas, 91 x 122 cm, 3 x 4 ft, 2019'


const Projects = () => {
    
        return (
            <div className="insidePageContainer">
                <div className="insidePageContainer__breadCrumbs">
                    <a><Link to ='/' className="insidePageContainer__link">HOME</Link></a>  /  <a><Link to ='/' className="insidePageContainer__link">BILLIONAIRE BUDDHA </Link></a> 
                </div>
               
                <div className="insidePageContainer__content">
                    <div className="billionaireBuddhaTitle">
                        THE BILLIONAIRE BUDDHA
                    </div>
                    <div className="billionaireBuddhaText">
                        The Billionaire Buddha is a metaphor that says peace, health and happiness in this life can be achieved by cultivation of the inner and outer worlds. The Buddha is the culmination of inner success and the Billionaire the epitome of material success. The paradigm of the Billionaire Buddha is that one must focus on both to achieve lasting peace in this world. This is in contrast to the prevailing one sided paradigms of either pursuing success in the material world or giving it up alltogether to seek spirituality. 
                    </div>
                    <div className="billionaireBuddhaTitle">
                    CONTEMPORIZING THE BODHISATTVA
                    </div>
                    <div className="billionaireBuddhaText">  
                    <p>Experiencing Arjuna's work is like taking a walk into a territory where one discovers elements, emotions and thoughts of the artist. Imagery that is culled from his conscious and subconscious mind manifest on the canvas teasing both the viewer's eye and the mind to engage with them. The central figure of the Lord Buddha is key to the composition as his avatars manifests in the centre of the composition. Arjun's interpretation reinvents the Buddhist Thanka style adapting it to oil on canvas and bringing to it a modernist methodology.</p>
                    <p>However, where the magic realism really happens is in the surrounding dreamscape around the Lord Buddha, the wild birds and beasts, the intoxicating flowers that drip colour in a maddening palette, and the evocation of the cosmos through the expressionstic swirls and motifs that create a sense of energy and vivacity around the canvas drawing you in to the reverie.</p> 
                    <p>Arjuna has many years of training in art especially in classical drawing and painting as well as in martial arts, mediation and music-- both classical as well as contemporary. All these aspects are a way of life that enrich his journey as an artist.</p> 
                    <p>They complete his creative cycle and bring to his canvases that sense of completion and fulfilment that may be then further derived from his work.</p>

                    <p><b>Georgina Maddox, Critic-Curator</b></p>
                    <p>New Delhi, January 2023</p> 
                
                    </div>
                    <Card image={Image1} text1a={text1a} text1b={text1b} imageLarge={ImageLarge1} reddot="true" />
                    <Card image={Image2} text1a={text2a} text1b={text2b} imageLarge={ImageLarge2} reddot="true"/>
                    <Card image={Image3} text1a={text3a} text1b={text3b} imageLarge={ImageLarge3} />
                    <Card image={Image4} text1a={text4a} text1b={text4b} imageLarge={ImageLarge4} />
                    <Card image={Image5} text1a={text5a} text1b={text5b} imageLarge={ImageLarge5} />
                    <Card image={Image6} text1a={text6a} text1b={text6b} imageLarge={ImageLarge6} reddot="true"/>
                    <Card image={Image7} text1a={text7a} text1b={text7b} imageLarge={ImageLarge7} reddot="true"/>
                    <Card image={Image8} text1a={text8a} text1b={text8b} imageLarge={ImageLarge8} />
                    <Card image={Image9} text1a={text9a} text1b={text9b} imageLarge={ImageLarge9} reddot="true"/>
                    <Card image={Image10} text1a={text10a} text1b={text10b} imageLarge={ImageLarge10} />
                    <Card image={Image11} text1a={text11a} text1b={text11b} imageLarge={ImageLarge11} />
                    <Card image={Image12} text1a={text12a} text1b={text12b} imageLarge={ImageLarge12} />
                    <Card image={Image13} text1a={text13a} text1b={text13b} imageLarge={ImageLarge13} />
                    <Card image={Image14} text1a={text14a} text1b={text14b} imageLarge={ImageLarge14} />
                    <Card image={Image15} text1a={text15a} text1b={text15b} imageLarge={ImageLarge15} />
                    <Card image={Image16} text1a={text16a} text1b={text16b} imageLarge={ImageLarge16} />
                    <Card image={Image17} text1a={text17a} text1b={text17b} imageLarge={ImageLarge17} reddot="true"/>
                    <Card image={Image18} text1a={text18a} text1b={text18b} imageLarge={ImageLarge18} />
                    <Card image={Image19} text1a={text19a} text1b={text19b} imageLarge={ImageLarge19} />
                    <Card image={Image20} text1a={text20a} text1b={text20b} imageLarge={ImageLarge20} />
                    <Card image={Image21} text1a={text21a} text1b={text21b} imageLarge={ImageLarge21} />
                    <Card image={Image22} text1a={text22a} text1b={text22b} imageLarge={ImageLarge22} />
                    <Card image={Image23} text1a={text23a} text1b={text23b} imageLarge={ImageLarge23} />
                    <Card image={Image24} text1a={text24a} text1b={text24b} imageLarge={ImageLarge24} />
                    <Card image={Image25} text1a={text25a} text1b={text25b} imageLarge={ImageLarge25} />
                    <Card image={Image26} text1a={text26a} text1b={text26b} imageLarge={ImageLarge26} />
                    <Card image={Image27} text1a={text27a} text1b={text27b} imageLarge={ImageLarge27} />
                    <Card image={Image28} text1a={text28a} text1b={text28b} imageLarge={ImageLarge28} />
                    <Card image={Image29} text1a={text29a} text1b={text29b} imageLarge={ImageLarge29} />
                    

                </div>
                <Footer />
            </div>
        )
    }

export default Projects;