import React from "react";
import { Link } from 'react-router-dom';
import Footer from "./footer";
import Card from "./cardCatalogs";

import Image1 from "./images/catalogs/JourneyFromSamsaraToNirvana/0001.jpg";
import Image2 from "./images/catalogs/JourneyFromSamsaraToNirvana/0002.jpg";
import Image3 from "./images/catalogs/JourneyFromSamsaraToNirvana/0003.jpg";
import Image4 from "./images/catalogs/JourneyFromSamsaraToNirvana/0004.jpg";
import Image5 from "./images/catalogs/JourneyFromSamsaraToNirvana/0005.jpg";
import Image6 from "./images/catalogs/JourneyFromSamsaraToNirvana/0006.jpg";
import Image7 from "./images/catalogs/JourneyFromSamsaraToNirvana/0007.jpg";
import Image8 from "./images/catalogs/JourneyFromSamsaraToNirvana/0008.jpg";
import Image9 from "./images/catalogs/JourneyFromSamsaraToNirvana/0009.jpg";
import Image10 from "./images/catalogs/JourneyFromSamsaraToNirvana/0010.jpg";
import Image11 from "./images/catalogs/JourneyFromSamsaraToNirvana/0011.jpg";
import Image12 from "./images/catalogs/JourneyFromSamsaraToNirvana/0012.jpg";

const Catalogs = () => {
    
        return (
            <div className="insidePageContainer">
                <div className="insidePageContainer__breadCrumbs">
                    <a><Link to ='/' className="insidePageContainer__link">HOME</Link></a>  /  <a><Link to ='/journeyfromsamsaratonirvana' className="insidePageContainer__link">JOURNEY FROM SAMSARA TO NIRVANA </Link></a> 
                </div>
               
                <div className="insidePageContainer__content">
                    <Card image={Image1} imageLarge={Image1} />
                    <Card image={Image2} imageLarge={Image2} />
                    <Card image={Image3} imageLarge={Image3} />
                    <Card image={Image4} imageLarge={Image4} />
                    <Card image={Image5} imageLarge={Image5} />
                    <Card image={Image6} imageLarge={Image6} />
                    <Card image={Image7} imageLarge={Image7} />
                    <Card image={Image8} imageLarge={Image8} />
                    <Card image={Image9} imageLarge={Image9} />
                    <Card image={Image10} imageLarge={Image10} />
                    <Card image={Image11} imageLarge={Image11} />
                    <Card image={Image12} imageLarge={Image12} />
                </div>
                <Footer />
            </div>
        )
    }

export default Catalogs;