import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MobileMenu extends Component {
    constructor(props)
    {
      super(props);
      this.state={
        showMobileMenu: true
      }
    }

    toggleShowMobileMenu = () => {
      this.setState({showMobileMenu: !this.state.showMobileMenu});
    } 

    render() {
        return (
            this.state.showMobileMenu && <div className="mobileMenu">
                <ul className="mobileMenu__menuItems"> 
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/' className="link">Home</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/classicaltraining' className="link">Classical Training</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/shows' className="link">Shows</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/arjuna' className="link">Arjuna</Link></a></li>
                    <li><a onClick={this.toggleShowMobileMenu}><Link to ='/contact' className="link">Contact</Link></a></li>
                </ul>
            </div>
        );
    }
}

export default MobileMenu;