import React from "react";
import { Link } from 'react-router-dom';
import Footer from "./footer";
import Card from "./cardCatalogs";

import Image1 from "./images/catalogs/ShivaDevi/0001.jpg";
import Image2 from "./images/catalogs/ShivaDevi/0002.jpg";
import Image3 from "./images/catalogs/ShivaDevi/0003.jpg";
import Image4 from "./images/catalogs/ShivaDevi/0004.jpg";
import Image5 from "./images/catalogs/ShivaDevi/0005.jpg";
import Image6 from "./images/catalogs/ShivaDevi/0006.jpg";
import Image7 from "./images/catalogs/ShivaDevi/0007.jpg";
import Image8 from "./images/catalogs/ShivaDevi/0008.jpg";
import Image9 from "./images/catalogs/ShivaDevi/0009.jpg";
import Image10 from "./images/catalogs/ShivaDevi/0010.jpg";
import Image11 from "./images/catalogs/ShivaDevi/0011.jpg";
import Image12 from "./images/catalogs/ShivaDevi/0012.jpg";
import Image13 from "./images/catalogs/ShivaDevi/0013.jpg";
import Image14 from "./images/catalogs/ShivaDevi/0014.jpg";
import Image15 from "./images/catalogs/ShivaDevi/0015.jpg";
import Image16 from "./images/catalogs/ShivaDevi/0016.jpg";
import Image17 from "./images/catalogs/ShivaDevi/0017.jpg";
import Image18 from "./images/catalogs/ShivaDevi/0018.jpg";
import Image19 from "./images/catalogs/ShivaDevi/0019.jpg";
import Image20 from "./images/catalogs/ShivaDevi/0020.jpg";
import Image21 from "./images/catalogs/ShivaDevi/0021.jpg";
import Image22 from "./images/catalogs/ShivaDevi/0022.jpg";
import Image23 from "./images/catalogs/ShivaDevi/0023.jpg";
import Image24 from "./images/catalogs/ShivaDevi/0024.jpg";

const Catalogs = () => {
    
        return (
            <div className="insidePageContainer">
                <div className="insidePageContainer__breadCrumbs">
                    <a><Link to ='/' className="insidePageContainer__link">HOME</Link></a>  /  <a><Link to ='/shivadevi' className="insidePageContainer__link">SHIVA AND THE DEVI </Link></a> 
                </div>
               
                <div className="insidePageContainer__content">
                    <Card image={Image1} imageLarge={Image1} />
                    <Card image={Image2} imageLarge={Image2} />
                    <Card image={Image3} imageLarge={Image3} />
                    <Card image={Image4} imageLarge={Image4} />
                    <Card image={Image5} imageLarge={Image5} />
                    <Card image={Image6} imageLarge={Image6} />
                    <Card image={Image7} imageLarge={Image7} />
                    <Card image={Image8} imageLarge={Image8} />
                    <Card image={Image9} imageLarge={Image9} />
                    <Card image={Image10} imageLarge={Image10} />
                    <Card image={Image11} imageLarge={Image11} />
                    <Card image={Image12} imageLarge={Image12} />
                    <Card image={Image13} imageLarge={Image13} />
                    <Card image={Image14} imageLarge={Image14} />
                    <Card image={Image15} imageLarge={Image15} />
                    <Card image={Image16} imageLarge={Image16} />
                    <Card image={Image17} imageLarge={Image17} />
                    <Card image={Image18} imageLarge={Image18} />
                    <Card image={Image19} imageLarge={Image19} />
                    <Card image={Image20} imageLarge={Image20} />
                    <Card image={Image21} imageLarge={Image21} />
                    <Card image={Image22} imageLarge={Image22} />
                    <Card image={Image23} imageLarge={Image23} />
                    <Card image={Image24} imageLarge={Image24} />
                </div>
                <Footer />
            </div>
        )
    }

export default Catalogs;