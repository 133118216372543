import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from "./footer";

const Arjuna = ()=> {

      return (
          <div className="insidePageContainer"> 
                <div className="insidePageContainer__breadCrumbs">
                        <a><Link to ='/' className="insidePageContainer__link">Home </Link></a> / <a><Link to ='/arjuna' className="insidePageContainer__link">Arjuna</Link></a> 
                </div>
                <div className="insidePageContainer__content">
                      <div className="billionaireBuddhaTitle">
                        ARJUNA
                    </div>
                    <div className="billionaireBuddhaText">
                        <p>Arjuna is by nature a polymathic artist. He is a painter, dhrupad singer, poet, writer and kungfu fighter.</p>
                        <p>He has trained with reputed masters in painting,(Virgil Elliot), Dhrupad (Ustad Zia Fariduddin Dagar, Nirmalya Dey, Ashish Sankrityayan, Marianne Svasek), Kungfu (Shaolin,Dengfeng City, Henan Province, China)</p>
                        <p> Arjuna has never stopped painting since childhood and has focused deeply on classical training in the European renaissance tradition. Arjuna has done numerous shows over the years and is currently working on a new series of paintings about the theme of The Billionaire Buddha.</p>  
                    <p>His current works are a fusion of raw creativity preserved and nurtured from childhood with rigorous traditional Atelier style classical training. </p>
                    </div>
                  
                        <Footer />
                  </div>
            </div>
      );
}


export default Arjuna;