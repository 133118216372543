import React from "react";
import { Link } from 'react-router-dom';
import Footer from "./footer";
import Card from "./cardCatalogs";

import Image1 from "./images/catalogs/sikhgurus/0001.jpg";
import Image2 from "./images/catalogs/sikhgurus/0002.jpg";
import Image3 from "./images/catalogs/sikhgurus/0003.jpg";
import Image4 from "./images/catalogs/sikhgurus/0004.jpg";
import Image5 from "./images/catalogs/sikhgurus/0005.jpg";
import Image6 from "./images/catalogs/sikhgurus/0006.jpg";
import Image7 from "./images/catalogs/sikhgurus/0007.jpg";
import Image8 from "./images/catalogs/sikhgurus/0008.jpg";
import Image9 from "./images/catalogs/sikhgurus/0009.jpg";

const Catalogs = () => {
    
        return (
            <div className="insidePageContainer">
                <div className="insidePageContainer__breadCrumbs">
                    <a><Link to ='/' className="insidePageContainer__link">HOME</Link></a>  /  <a><Link to ='/sikhgurus' className="insidePageContainer__link">THE SIKH GURUS </Link></a> 
                </div>
               
                <div className="insidePageContainer__content">
                    <Card image={Image1} imageLarge={Image1} />
                    <Card image={Image2} imageLarge={Image2} />
                    <Card image={Image3} imageLarge={Image3} />
                    <Card image={Image4} imageLarge={Image4} />
                    <Card image={Image5} imageLarge={Image5} />
                    <Card image={Image6} imageLarge={Image6} />
                    <Card image={Image7} imageLarge={Image7} />
                    <Card image={Image8} imageLarge={Image8} />
                    <Card image={Image9} imageLarge={Image9} />
                </div>
                <Footer />
            </div>
        )
    }

export default Catalogs;